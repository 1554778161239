/* Global Styles - Modern Professional Theme */
:root {
  /* Primary brand colors - AWS blue professional palette */
  --primary: #0972D3; /* AWS blue - primary */
  --primary-light: #1E88E5; /* Lighter blue - for hover states */
  --primary-dark: #0A5BBF; /* Darker blue - for active states */
  --secondary: #f0f4f8; /* Light blue-gray - secondary color */
  --accent: #0972D3; /* Blue accent (same as primary) */
  
  /* UI colors - light, clean and professional */
  --bg-light: #ffffff; /* Pure white background */
  --bg-dark: #ffffff; /* White background */
  --bg-card: #f8fafc; /* Very light gray card background */
  --text-dark: #1e293b; /* Dark slate text */
  --text-light: #f8fafc; /* Off-white text */
  --text-muted: #64748b; /* Muted slate text */
  
  /* Functional colors - professional palette */
  --success: #38a169; /* Fresh green */
  --warning: #b7791f; /* Amber */
  --danger: #e53e3e; /* Vibrant red */
  
  /* Gray scale - cool, professional tones */
  --gray-100: #f8fafc;
  --gray-200: #f1f5f9;
  --gray-300: #e2e8f0;
  --gray-400: #cbd5e0;
  --gray-500: #94a3b8;
  --gray-600: #64748b;
  --gray-700: #475569;
  --gray-800: #334155;
  --gray-900: #1e293b;
  
  /* UI properties */
  --radius-sm: 2px;
  --radius-md: 4px;
  --radius-lg: 8px;
  --shadow-sm: 0 1px 3px rgba(45,35,29,0.08), 0 1px 2px rgba(45,35,29,0.16);
  --shadow-md: 0 4px 6px rgba(45,35,29,0.08), 0 1px 3px rgba(45,35,29,0.12);
  --shadow-lg: 0 10px 15px rgba(45,35,29,0.08), 0 4px 6px rgba(45,35,29,0.12);
  --transition-normal: all 0.3s ease;
  --container-max-width: 1200px;
  --sidebar-width: 250px;
  --header-height: 70px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Utility Classes */
.text-center { text-align: center; }
.text-right { text-align: right; }
.text-left { text-align: left; }
.text-primary { color: var(--primary); }
.text-danger { color: var(--danger); }
.text-success { color: var(--success); }
.text-warning { color: var(--warning); }
.text-muted { color: var(--text-muted); }

.mb-1 { margin-bottom: 4px; }
.mb-2 { margin-bottom: 8px; }
.mb-3 { margin-bottom: 12px; }
.mb-4 { margin-bottom: 16px; }
.mb-5 { margin-bottom: 24px; }
.mt-1 { margin-top: 4px; }
.mt-2 { margin-top: 8px; }
.mt-3 { margin-top: 12px; }
.mt-4 { margin-top: 16px; }
.mt-5 { margin-top: 24px; }

.p-0 { padding: 0; }
.p-1 { padding: 4px; }
.p-2 { padding: 8px; }
.p-3 { padding: 12px; }
.p-4 { padding: 16px; }
.p-5 { padding: 24px; }

.d-flex { display: flex; }
.flex-column { flex-direction: column; }
.flex-row { flex-direction: row; }
.justify-center { justify-content: center; }
.align-center { align-items: center; }
.justify-between { justify-content: space-between; }
.justify-around { justify-content: space-around; }
.flex-wrap { flex-wrap: wrap; }
.flex-grow-1 { flex-grow: 1; }

.btn-lg {
  padding: 12px 32px;
  font-size: 16px;
}

body {
  background-color: var(--bg-dark);
  color: var(--text-dark);
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* Layout */
.app-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  display: flex;
  flex: 1;
}

.content-area {
  flex: 1;
  padding: 24px;
  width: 100%;
}

.full-width {
  max-width: 100% !important;
  padding: 0 !important;
}

.container {
  max-width: var(--container-max-width);
  margin: 0 auto;
  padding: 0 24px;
  width: 100%;
}

@media (max-width: 768px) {
  .container {
    padding: 0 16px;
  }
}

/* Header - Modern Dark Style */
.header {
  background-color: white; /* White background to match logo */
  height: var(--header-height);
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 24px;
  justify-content: space-between;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(9, 114, 211, 0.2);
}

.header-logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--text-dark);
  font-weight: 600;
}

.header-logo {
  display: flex;
  align-items: center;
}

.header-logo-full {
  height: 38px;
  max-width: 180px;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.header-logo:hover .header-logo-full {
  transform: scale(1.05);
}

.app-name {
  font-size: 24px;
  font-weight: 600;
  color: var(--primary-dark);
  margin-left: 8px;
  position: relative;
  top: 1px;
}

.header-nav {
  display: flex;
  gap: 8px;
  align-items: center;
}

.header-link {
  padding: 8px 16px;
  border-radius: var(--radius-md);
  color: var(--text-dark);
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background: none;
  transition: all 0.2s ease;
  position: relative;
  opacity: 0.85;
}

.header-link:hover {
  color: var(--primary);
  opacity: 1;
  background-color: rgba(9, 114, 211, 0.05);
  transform: translateY(-1px);
}

.header-link.active {
  color: var(--primary);
  opacity: 1;
  background-color: rgba(9, 114, 211, 0.1);
}

.header-link.active::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--primary);
  border-radius: 2px;
  transform: scaleX(0.7);
  transition: transform 0.2s ease;
}

.header-link.active:hover::after {
  transform: scaleX(0.9);
}

.header-link.join-session {
  background-color: var(--primary);
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  opacity: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.header-link.join-session:hover {
  background-color: var(--primary-light);
  transform: translateY(-1px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
}

.header-icon {
  margin-right: 8px;
}

/* Dashboard Card Container */
.card {
  background-color: var(--bg-card);
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  margin-bottom: 32px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease, transform 0.2s ease;
}

.card-header {
  padding: 20px 28px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(9, 114, 211, 0.05);
}

.card-title {
  font-size: 18px;
  font-weight: 600;
  color: var(--primary-dark);
  letter-spacing: 0.5px;
}

.card-body {
  padding: 36px;
}

@media (max-width: 768px) {
  .card-body {
    padding: 24px 20px;
  }
  
  .card-header {
    padding: 16px 20px;
  }
}

/* Landing Page */
.landing-heading {
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 24px;
  color: var(--primary-dark);
  font-family: 'Poppins', sans-serif;
  position: relative;
  padding-bottom: 20px;
  text-align: center;
  letter-spacing: -0.02em;
}

.landing-heading::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 2px;
  background-color: var(--primary);
}

.landing-text {
  font-size: 18px;
  color: var(--text-muted);
  margin-bottom: 48px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  line-height: 1.8;
}

.landing-buttons {
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
}

@media (max-width: 576px) {
  .landing-buttons {
    flex-direction: column;
    align-items: center;
  }
}

.landing-features {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
  margin-bottom: 40px;
}

.feature {
  flex: 1;
  min-width: 250px;
  max-width: 350px;
  padding: 32px 24px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  text-align: center;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
}

.feature::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  transition: height 0.3s ease;
}

.feature:nth-child(1)::before {
  background-color: var(--primary);
}

.feature:nth-child(2)::before {
  background-color: var(--primary-light);
}

.feature:nth-child(3)::before {
  background-color: var(--primary-dark);
}

.feature:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.feature-icon {
  font-size: 26px;
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.07);
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.3s ease;
}

.feature:nth-child(1) .feature-icon {
  color: var(--primary);
}

.feature:nth-child(2) .feature-icon {
  color: var(--primary-light);
}

.feature:nth-child(3) .feature-icon {
  color: var(--primary-dark);
}

.feature:hover .feature-icon {
  transform: scale(1.05);
  background-color: rgba(255, 255, 255, 0.12);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
}

.feature h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
  color: var(--primary-dark);
  font-family: 'Poppins', sans-serif;
}

.feature p {
  color: var(--text-muted);
  line-height: 1.7;
  font-size: 15px;
}

@media (max-width: 768px) {
  .feature {
    min-width: 100%;
  }
  
  .landing-heading {
    font-size: 32px;
  }
  
  .landing-text {
    font-size: 16px;
  }
}

/* Dashboard */
.dashboard-container {
  max-width: 1200px;
  margin: 0 auto;
}

.dashboard-header {
  margin-bottom: 32px;
  text-align: center;
}

.dashboard-header h1 {
  color: var(--primary-dark);
  font-size: 28px;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.25px;
  font-weight: 600;
}

.user-role {
  font-size: 16px;
  color: var(--text-muted);
  font-style: italic;
}

/* New two-column layout */
.dashboard-content {
  display: flex;
  gap: 24px;
}

.dashboard-main {
  flex: 1;
  min-width: 0; /* Required for proper flex behavior with overflow */
}

.dashboard-sidebar {
  width: 320px;
  min-width: 320px;
}

/* Sidebar components */
.sidebar-card {
  background-color: white;
  border-radius: var(--radius-md);
  padding: 20px;
  box-shadow: var(--shadow-sm);
  margin-bottom: 24px;
  border: 1px solid var(--gray-200);
}

.sidebar-card h3 {
  font-size: 18px;
  margin-bottom: 16px;
  color: var(--primary-dark);
  font-weight: 600;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--gray-200);
}

.next-action-card {
  background-color: var(--primary);
  border-radius: var(--radius-md);
  padding: 24px;
  box-shadow: var(--shadow-sm);
  margin-bottom: 24px;
  color: white;
  text-align: center;
}

.next-action-card h3 {
  font-size: 20px;
  margin-bottom: 8px;
  font-weight: 600;
}

.next-action-card p {
  margin-bottom: 16px;
  opacity: 0.9;
}

.next-action-card .btn {
  margin-top: 8px;
  width: 100%;
  justify-content: center;
  font-weight: 600;
  background-color: white;
  color: var(--primary);
  border: none;
  padding: 10px 16px;
  transition: all 0.2s ease;
}

.next-action-card .btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.btn-icon {
  margin-right: 8px;
}

.full-width {
  width: 100%;
}

/* Sidebar Stats */
.dashboard-stats {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.stat-item {
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: var(--gray-100);
  border-radius: var(--radius-md);
  transition: all 0.2s ease;
}

.stat-item:hover {
  background-color: var(--gray-200);
  transform: translateY(-2px);
}

.stat-icon {
  font-size: 24px;
  color: var(--primary);
  background-color: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 16px;
  box-shadow: var(--shadow-sm);
}

.stat-details {
  flex: 1;
}

.stat-label {
  font-size: 14px;
  color: var(--text-muted);
  margin: 0;
}

.stat-value {
  font-size: 20px;
  font-weight: 600;
  color: var(--text-dark);
  margin: 0;
}

/* Sidebar Actions */
.sidebar-actions {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.action-button {
  width: 100%;
  background-color: white;
  border: 1px solid var(--gray-300);
  border-radius: var(--radius-md);
  padding: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: var(--transition-normal);
}

.action-button:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
  border-color: var(--primary-light);
  background-color: var(--gray-100);
}

.action-button.primary {
  background-color: var(--primary);
  border-color: var(--primary-dark);
  color: var(--text-light);
}

.action-button.primary:hover {
  background-color: var(--primary-light);
}

.action-icon {
  font-size: 18px;
  color: var(--primary);
  margin-right: 12px;
}

.action-button span {
  font-size: 14px;
  font-weight: 500;
}

/* Main content sections */
.dashboard-bookings,
.dashboard-completed-sessions,
.dashboard-availability {
  background-color: white;
  border-radius: var(--radius-md);
  padding: 24px;
  box-shadow: var(--shadow-sm);
  margin-bottom: 32px;
  border: 1px solid var(--gray-200);
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--gray-200);
}

.section-header h2 {
  font-size: 20px;
  color: var(--primary-dark);
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  margin: 0;
}

.btn-link {
  background: none;
  border: none;
  color: var(--primary);
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
}

.btn-link:hover {
  text-decoration: underline;
}

.recent-bookings {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 20px;
}

.completed-sessions-toggle {
  display: flex;
  justify-content: center;
  margin: 24px 0;
  padding: 16px;
  background-color: var(--gray-100);
  border-radius: var(--radius-md);
  border: 1px dashed var(--gray-300);
}

.no-bookings,
.no-slots {
  background-color: var(--gray-100);
  border-radius: var(--radius-md);
  padding: 32px;
  text-align: center;
  border: 1px dashed var(--gray-300);
}

.no-bookings p,
.no-slots p {
  margin-bottom: 16px;
  color: var(--text-muted);
  font-size: 16px;
}

.slots-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 20px;
  margin-top: 16px;
}

.slot-card {
  background-color: white;
  border-radius: var(--radius-md);
  overflow: hidden;
  border: 1px solid var(--gray-300);
  box-shadow: var(--shadow-sm);
  transition: var(--transition-normal);
}

.slot-card:hover {
  transform: translateY(-3px);
  box-shadow: var(--shadow-md);
}

.slot-card.available {
  border-left: 4px solid var(--success);
}

.slot-header {
  padding: 16px;
  border-bottom: 1px solid var(--gray-200);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slot-header h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-dark);
}

.slot-details {
  padding: 16px;
}

.slot-details p {
  margin: 8px 0;
  font-size: 14px;
}

.slot-details p:first-child {
  margin-top: 0;
}

.slot-details p:last-child {
  margin-bottom: 0;
}

@media (max-width: 992px) {
  .dashboard-content {
    flex-direction: column-reverse;
  }
  
  .dashboard-sidebar {
    width: 100%;
    min-width: 100%;
  }
  
  .sidebar-card {
    margin-bottom: 16px;
  }
  
  .sidebar-actions {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .action-button {
    flex: 1;
    min-width: 140px;
  }
  
  .recent-bookings,
  .slots-container {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}

@media (max-width: 768px) {
  .recent-bookings,
  .slots-container {
    grid-template-columns: 1fr;
  }
}

/* Forms */
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: var(--gray-700);
}

.form-row {
  display: flex;
  gap: 16px;
}

.form-row .form-group {
  flex: 1;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="date"],
input[type="time"],
textarea,
select {
  width: 100%;
  padding: 12px 16px;
  font-size: 14px;
  border: 1px solid var(--gray-300);
  border-radius: var(--radius-md);
  background-color: white;
  transition: var(--transition-normal);
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  border-color: var(--primary);
  box-shadow: 0 0 0 3px rgba(74, 107, 255, 0.1);
}

textarea {
  min-height: 100px;
  resize: vertical;
}

/* Buttons */
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  border-radius: var(--radius-md);
  transition: var(--transition-normal);
}

.btn-primary {
  color: white;
  background: linear-gradient(145deg, var(--primary), var(--primary-dark));
  border-color: var(--primary);
  position: relative;
  overflow: hidden;
}

.btn-primary:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.btn-primary:hover {
  background: linear-gradient(145deg, var(--primary-light), var(--primary));
  border-color: var(--primary-light);
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(9, 114, 211, 0.25);
}

.btn-primary:hover:before {
  width: 100%;
}

.btn-secondary {
  color: var(--text-dark);
  background-color: var(--gray-200);
  border-color: var(--gray-300);
}

.btn-secondary:hover {
  background-color: var(--gray-300);
}

.btn-success {
  color: white;
  background-color: var(--success);
  border-color: var(--success);
}

.btn-success:hover {
  background-color: #3da066;
}

.btn-danger {
  color: white;
  background-color: var(--danger);
  border-color: var(--danger);
}

.btn-danger:hover {
  background-color: #e53e3e;
}

.btn-link {
  color: var(--primary);
  background-color: transparent;
  text-decoration: none;
  padding: 0;
  font-weight: 500;
}

.btn-link:hover {
  text-decoration: underline;
}

.btn-back {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: var(--gray-600);
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
}

.btn-back:hover {
  color: var(--primary);
}

.btn.btn-delete {
  color: var(--danger);
  background-color: transparent;
  border: 1px solid var(--danger);
}

.btn.btn-delete:hover {
  background-color: var(--danger);
  color: white;
}

.btn-disabled, button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background-color: var(--gray-400) !important;
  border-color: var(--gray-400) !important;
  color: var(--gray-600) !important;
}

/* Profile Form */
.profile-form-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.profile-form {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  border-radius: var(--radius-lg);
}

.form-title {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 8px;
  color: var(--primary);
  text-align: center;
}

.form-subtitle {
  color: var(--gray-600);
  text-align: center;
  margin-bottom: 32px;
}

.form-section {
  margin-bottom: 32px;
  background-color: white;
  padding: 24px;
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-sm);
}

.section-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--gray-200);
  color: var(--gray-800);
}

.profile-photo-section {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.photo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-photo {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 16px;
  border: 4px solid var(--primary);
  box-shadow: var(--shadow-md);
}

.photo-upload-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: var(--gray-100);
  border: 1px solid var(--gray-300);
  border-radius: var(--radius-md);
  cursor: pointer;
  transition: var(--transition-normal);
}

.photo-upload-btn:hover {
  background-color: var(--gray-200);
}

.upload-icon {
  color: var(--primary);
}

.hidden-input {
  display: none;
}

.form-text {
  color: var(--gray-600);
  font-size: 13px;
  margin-top: 6px;
  display: block;
}

.role-selector {
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-top: 16px;
}

.role-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 150px;
  background-color: var(--gray-100);
  border: 2px solid var(--gray-300);
  border-radius: var(--radius-md);
  cursor: pointer;
  transition: var(--transition-normal);
}

.role-btn:hover {
  background-color: var(--gray-200);
}

.role-btn.active {
  background-color: rgba(74, 107, 255, 0.1);
  border-color: var(--primary);
}

.role-icon {
  font-size: 24px;
  color: var(--primary);
  margin-bottom: 12px;
}

.role-btn span {
  font-weight: 500;
}

/* Admin Badge Styling */
.admin-badge {
  background-color: rgba(0, 0, 0, 0.05);
  border-color: rgba(0, 0, 0, 0.2);
  cursor: default;
  position: relative;
  overflow: visible;
}

.admin-badge:hover {
  transform: none;
  background-color: rgba(0, 0, 0, 0.05);
}

.admin-note {
  display: block;
  font-size: 10px;
  font-weight: normal;
  color: var(--text-muted);
  margin-top: 6px;
  font-style: italic;
}

.tags-input-container {
  border: 1px solid var(--gray-300);
  border-radius: var(--radius-md);
  padding: 8px;
  background-color: white;
  margin-bottom: 8px;
}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
}

.tag {
  display: flex;
  align-items: center;
  background-color: rgba(74, 107, 255, 0.1);
  border: 1px solid rgba(74, 107, 255, 0.3);
  border-radius: 16px;
  padding: 4px 12px;
  font-size: 13px;
}

.tag span {
  margin-right: 4px;
}

.tag-remove {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--gray-600);
  display: flex;
  align-items: center;
  padding: 2px;
}

.tag-remove:hover {
  color: var(--danger);
}

.tags-input {
  display: flex;
  gap: 8px;
}

.tag-add-btn {
  background-color: var(--gray-200);
  border: none;
  width: 36px;
  border-radius: var(--radius-md);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--gray-700);
}

.tag-add-btn:hover {
  background-color: var(--gray-300);
  color: var(--gray-900);
}

.form-actions {
  margin-top: 32px;
  display: flex;
  justify-content: center;
}

/* Teacher Schedule - Original List View */
.teacher-schedule {
  width: 100%;
}

.availability-form {
  margin-bottom: 40px;
  background-color: white;
  padding: 24px;
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-sm);
}

.availability-list {
  margin-top: 40px;
}

.slots-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
  margin-top: 16px;
}

.slot-card {
  background-color: white;
  border-radius: var(--radius-md);
  overflow: hidden;
  box-shadow: var(--shadow-sm);
  border-left: 4px solid var(--primary);
  transition: var(--transition-normal);
}

.slot-card:hover {
  box-shadow: var(--shadow-md);
  transform: translateY(-3px);
}

.slot-card.booked {
  border-left-color: var(--success);
}

.slot-card.past {
  border-left-color: var(--gray-400);
  opacity: 0.7;
}

.slot-header {
  padding: 16px;
  border-bottom: 1px solid var(--gray-200);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slot-header h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.slot-details {
  padding: 16px;
}

.slot-details p {
  margin-bottom: 8px;
  font-size: 14px;
}

.status-badge {
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 12px;
  font-weight: 500;
  background-color: var(--gray-200);
}

.status-badge.available {
  background-color: var(--success);
  color: white;
}

.status-badge.booked {
  background-color: var(--primary);
  color: white;
}

/* Teacher Calendar Schedule - New Calendar View */
.teacher-calendar {
  width: 100%;
  font-family: 'Poppins', sans-serif;
}

.teacher-calendar h2 {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  color: var(--primary-dark);
}

.calendar-icon {
  margin-right: 12px;
  color: var(--primary);
}

.calendar-controls {
  display: flex;
  gap: 12px;
  margin-bottom: 20px;
  justify-content: center;
}

.calendar-actions {
  margin-bottom: 20px;
}

.bulk-action-panel {
  display: flex;
  gap: 12px;
  padding: 12px;
  background-color: var(--secondary);
  border-radius: var(--radius-md);
  align-items: center;
}

.bulk-action-panel span {
  font-weight: 500;
  margin-right: auto;
}

.availability-calendar {
  background-color: white;
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-sm);
  margin-bottom: 24px;
  overflow: hidden;
  border: 1px solid var(--gray-300);
}

.calendar-header {
  display: flex;
  border-bottom: 1px solid var(--gray-300);
}

.time-column-header {
  width: 80px;
  min-width: 80px;
  border-right: 1px solid var(--gray-300);
}

.day-column-header {
  flex: 1;
  padding: 12px 8px;
  text-align: center;
  border-right: 1px solid var(--gray-300);
}

.day-column-header:last-child {
  border-right: none;
}

.day-column-header.today {
  background-color: rgba(9, 114, 211, 0.08);
}

.day-name {
  font-weight: 600;
  color: var(--primary-dark);
  font-size: 14px;
}

.day-date {
  color: var(--text-muted);
  font-size: 13px;
}

.calendar-body {
  max-height: 800px;
  overflow-y: auto;
}

.time-row {
  display: flex;
  border-bottom: 1px solid var(--gray-200);
}

.time-row:last-child {
  border-bottom: none;
}

.time-label {
  width: 80px;
  min-width: 80px;
  padding: 10px 8px;
  font-size: 12px;
  text-align: right;
  color: var(--text-muted);
  font-weight: 500;
  border-right: 1px solid var(--gray-300);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.time-slot {
  flex: 1;
  height: 40px;
  border-right: 1px solid var(--gray-200);
  cursor: pointer;
  transition: background-color 0.15s ease;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.time-slot:last-child {
  border-right: none;
}

.time-slot:hover:not(.past) {
  background-color: rgba(9, 114, 211, 0.05);
}

.time-slot.available {
  background-color: rgba(56, 161, 105, 0.2);
  cursor: pointer;
}

.time-slot.available:hover {
  background-color: rgba(56, 161, 105, 0.3);
}

.time-slot.selected {
  background-color: rgba(9, 114, 211, 0.15);
}

.time-slot.selected:hover {
  background-color: rgba(9, 114, 211, 0.25);
}

.time-slot.past {
  background-color: rgba(0, 0, 0, 0.03);
  cursor: not-allowed;
}

.slot-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slot-icon.available {
  color: var(--success);
}

.slot-icon.selected {
  color: var(--primary);
}

.schedule-legend {
  display: flex;
  gap: 20px;
  margin-bottom: 24px;
  padding: 12px;
  background-color: white;
  border-radius: var(--radius-md);
  justify-content: center;
  border: 1px solid var(--gray-300);
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 13px;
}

.legend-color {
  width: 16px;
  height: 16px;
  border-radius: 4px;
}

.legend-color.available {
  background-color: rgba(56, 161, 105, 0.2);
  border: 1px solid rgba(56, 161, 105, 0.5);
}

.legend-color.selected {
  background-color: rgba(9, 114, 211, 0.15);
  border: 1px solid rgba(9, 114, 211, 0.3);
}

.legend-color.past {
  background-color: rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.schedule-instructions {
  margin-top: 24px;
  background-color: white;
  padding: 24px;
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-sm);
  border: 1px solid var(--gray-300);
}

.schedule-instructions h3 {
  margin-bottom: 16px;
  color: var(--primary-dark);
  font-size: 18px;
  font-weight: 600;
}

.schedule-instructions ul {
  padding-left: 20px;
  margin-bottom: 0;
}

.schedule-instructions li {
  margin-bottom: 8px;
  color: var(--text-dark);
  font-size: 14px;
}

@media (max-width: 768px) {
  .time-label {
    width: 60px;
    min-width: 60px;
    font-size: 10px;
  }
  
  .time-slot {
    height: 50px;
  }
  
  .bulk-action-panel {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .calendar-controls {
    flex-direction: column;
  }
}

/* Payment System styles */
.session-price {
  display: flex;
  align-items: center;
}

.price-tag {
  background-color: var(--primary);
  color: white;
  padding: 4px 10px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.price {
  font-weight: 600;
  color: var(--primary-dark);
  font-size: 15px;
}

/* Admin Panel Styles */
.admin-panel {
  width: 100%;
}

.admin-header {
  margin-bottom: 24px;
  text-align: center;
}

.admin-header h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: 600;
  color: var(--primary-dark);
  margin-bottom: 8px;
}

.admin-header p {
  color: var(--text-muted);
}

.admin-container {
  display: flex;
  gap: 24px;
  margin-top: 16px;
}

.admin-sidebar {
  width: 250px;
  min-width: 250px;
}

.admin-nav {
  background-color: white;
  border-radius: var(--radius-md);
  overflow: hidden;
  box-shadow: var(--shadow-sm);
  border: 1px solid var(--gray-200);
}

.admin-nav-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 14px 16px;
  width: 100%;
  text-align: left;
  border: none;
  background: none;
  border-bottom: 1px solid var(--gray-200);
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
  color: var(--gray-700);
}

.admin-nav-item:last-child {
  border-bottom: none;
}

.admin-nav-item:hover {
  background-color: var(--gray-100);
  color: var(--primary);
}

.admin-nav-item.active {
  background-color: var(--primary);
  color: white;
}

.admin-content {
  flex: 1;
  background-color: white;
  border-radius: var(--radius-md);
  padding: 24px;
  box-shadow: var(--shadow-sm);
  border: 1px solid var(--gray-200);
}

.admin-section-header {
  margin-bottom: 24px;
}

.admin-section-header h3 {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  font-weight: 600;
  color: var(--primary-dark);
  margin-bottom: 8px;
}

.admin-section-header p {
  color: var(--text-muted);
}

.admin-dashboard {
  padding: 0 0 24px 0;
}

.admin-stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  margin-bottom: 32px;
}

.admin-stat-card {
  background-color: var(--gray-100);
  border-radius: var(--radius-md);
  padding: 16px;
  text-align: center;
  border: 1px solid var(--gray-300);
  transition: transform 0.2s ease;
}

.admin-stat-card:hover {
  transform: translateY(-2px);
  border-color: var(--primary);
}

.admin-stat-card h4 {
  font-size: 14px;
  color: var(--gray-600);
  margin-bottom: 8px;
}

.stat-value {
  font-size: 24px;
  font-weight: 600;
  color: var(--gray-900);
  margin-bottom: 6px;
}

.stat-description {
  font-size: 12px;
  color: var(--gray-600);
}

.admin-quick-actions {
  margin-top: 32px;
}

.admin-actions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

.admin-action-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: white;
  border: 1px solid var(--gray-300);
  border-radius: var(--radius-md);
  cursor: pointer;
  transition: all 0.2s ease;
  gap: 10px;
}

.admin-action-button:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-sm);
  border-color: var(--primary);
}

.admin-action-button svg {
  font-size: 24px;
  color: var(--primary);
}

.admin-action-button span {
  font-weight: 500;
}

.admin-restricted {
  text-align: center;
  padding: 50px 20px;
  background-color: var(--gray-100);
  border-radius: var(--radius-md);
  border: 1px dashed var(--gray-400);
  margin: 30px auto;
  max-width: 500px;
}

.admin-icon {
  color: var(--gray-600);
  margin-bottom: 16px;
}

.admin-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

.spinner {
  animation: spin 1s infinite linear;
  font-size: 30px;
  color: var(--primary);
  margin-bottom: 16px;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Payment Settings Styles */
.payment-settings {
  padding-bottom: 24px;
}

.test-mode-badge {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 4px 10px;
  background-color: rgba(246, 173, 85, 0.2);
  border: 1px solid rgba(246, 173, 85, 0.4);
  border-radius: 12px;
  font-size: 12px;
  font-weight: 600;
  color: #b7791f;
  margin-top: 8px;
}

.razorpay-config-form {
  margin-top: 24px;
  margin-bottom: 32px;
  border: 1px solid var(--gray-300);
  border-radius: var(--radius-md);
  padding: 24px;
  background-color: var(--gray-50);
}

.form-actions {
  display: flex;
  gap: 16px;
  margin-top: 24px;
}

.razorpay-info {
  background-color: var(--gray-100);
  border-radius: var(--radius-md);
  padding: 20px;
  margin-top: 24px;
  border: 1px solid var(--gray-300);
}

.razorpay-info h4 {
  margin-bottom: 16px;
  color: var(--gray-700);
}

.razorpay-info ol {
  padding-left: 20px;
  margin-bottom: 16px;
}

.razorpay-info li {
  margin-bottom: 8px;
}

.razorpay-info a {
  color: var(--primary);
  text-decoration: none;
}

.razorpay-info a:hover {
  text-decoration: underline;
}

/* Financial Reports Styles */
.financial-reports {
  padding-bottom: 24px;
}

.report-filters {
  margin-bottom: 24px;
  background-color: var(--gray-50);
  border-radius: var(--radius-md);
  padding: 16px;
  border: 1px solid var(--gray-300);
}

.report-filters h4 {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  font-size: 16px;
  color: var(--gray-700);
}

.filter-form .form-row {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.form-actions-inline {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.summary-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  margin-bottom: 32px;
}

.summary-card {
  background-color: white;
  border-radius: var(--radius-md);
  padding: 16px;
  text-align: center;
  border: 1px solid var(--gray-300);
  box-shadow: var(--shadow-sm);
}

.summary-card h4 {
  font-size: 14px;
  color: var(--gray-600);
  margin-bottom: 8px;
}

.summary-value {
  font-size: 24px;
  font-weight: 600;
  color: var(--gray-900);
}

.teacher-summary-section, .payments-section {
  margin-top: 32px;
}

.teacher-summary-section h4, .payments-section h4 {
  font-size: 18px;
  font-weight: 600;
  color: var(--gray-800);
  margin-bottom: 16px;
}

.teacher-summary-table, .payments-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 24px;
}

.teacher-summary-table th, .payments-table th,
.teacher-summary-table td, .payments-table td {
  padding: 12px 16px;
  border: 1px solid var(--gray-300);
  text-align: left;
}

.teacher-summary-table th, .payments-table th {
  background-color: var(--gray-100);
  font-weight: 600;
  color: var(--gray-700);
}

.teacher-summary-table tr:hover, .payments-table tr:hover {
  background-color: var(--gray-50);
}

.no-data {
  padding: 32px;
  text-align: center;
  background-color: var(--gray-100);
  border-radius: var(--radius-md);
  border: 1px dashed var(--gray-300);
  color: var(--gray-600);
}

@media (max-width: 992px) {
  .admin-container {
    flex-direction: column;
  }
  
  .admin-sidebar {
    width: 100%;
    min-width: 100%;
  }
  
  .admin-nav {
    display: flex;
    flex-wrap: wrap;
  }
  
  .admin-nav-item {
    flex: 1;
    text-align: center;
    justify-content: center;
  }
}

/* Teacher Search */
.teacher-search {
  width: 100%;
}

.search-form {
  max-width: 600px;
  margin: 0 auto 40px;
  background-color: white;
  padding: 24px;
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-sm);
}

.search-type-selector {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.search-type-option {
  display: flex;
  align-items: center;
  gap: 6px;
}

.search-type-option input[type="radio"] {
  margin: 0;
  accent-color: var(--primary);
}

.search-type-option label {
  font-weight: normal;
  margin: 0;
  cursor: pointer;
}

.search-subtitle {
  color: var(--text-muted);
  margin-bottom: 20px;
  font-size: 16px;
}

.search-results {
  margin-top: 40px;
}

.teacher-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
}

.teacher-card {
  background-color: white;
  border-radius: var(--radius-md);
  overflow: hidden;
  box-shadow: var(--shadow-sm);
  transition: var(--transition-normal);
}

.teacher-card:hover {
  box-shadow: var(--shadow-md);
  transform: translateY(-3px);
}

.teacher-card-header {
  padding: 16px;
  background-color: var(--gray-100);
  border-bottom: 1px solid var(--gray-200);
  display: flex;
  align-items: center;
}

.teacher-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 16px;
  border: 2px solid var(--primary);
}

.teacher-card-body {
  padding: 16px;
}

.teacher-card-body p {
  margin-bottom: 8px;
  font-size: 14px;
}

.teacher-card-footer {
  padding: 16px;
  border-top: 1px solid var(--gray-200);
  text-align: right;
}

.teacher-details {
  margin-top: 24px;
}

/* Bookings */
.bookings-container {
  width: 100%;
}

/* Swimlanes */
.swimlane {
  margin-bottom: 32px;
  background-color: white;
  border-radius: var(--radius-md);
  padding: 24px;
  box-shadow: var(--shadow-sm);
  border: 1px solid var(--gray-200);
}

.swimlane-title {
  font-size: 20px;
  font-weight: 600;
  padding: 0 0 12px 0;
  margin-bottom: 20px;
  color: var(--primary-dark);
  border-bottom: 1px solid var(--gray-200);
  position: relative;
  display: flex;
  align-items: center;
}

.swimlane-title::before {
  content: '';
  display: block;
  width: 4px;
  height: 20px;
  background-color: var(--primary);
  margin-right: 10px;
  border-radius: 2px;
}

.bookings-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 24px;
  margin-top: 16px;
}

.booking-card {
  background-color: white;
  border-radius: var(--radius-md);
  overflow: hidden;
  box-shadow: var(--shadow-sm);
  border: 1px solid var(--gray-300);
  border-left: 4px solid var(--primary);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  position: relative;
}

.booking-card:hover {
  transform: translateY(-3px);
  box-shadow: var(--shadow-md);
}

.booking-card.joinable {
  border-left-color: var(--success);
  box-shadow: 0 0 0 1px rgba(56, 161, 105, 0.1), 0 3px 10px rgba(56, 161, 105, 0.15);
}

.booking-card.joinable::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 24px 24px 0;
  border-color: transparent var(--success) transparent transparent;
  z-index: 1;
}

.booking-card.upcoming {
  border-left-color: var(--primary);
}

.booking-card.past {
  border-left-color: var(--gray-400);
  opacity: 0.8;
  background-color: var(--gray-100);
}

.booking-card.cancelled {
  border-left-color: var(--danger);
  opacity: 0.8;
  background-color: rgba(245, 101, 101, 0.05);
}

.booking-header {
  padding: 16px;
  border-bottom: 1px solid var(--gray-200);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.booking-header h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.booking-details {
  padding: 16px;
}

.booking-details p {
  margin-bottom: 8px;
  font-size: 14px;
}

.booking-actions {
  padding: 16px;
  border-top: 1px solid var(--gray-200);
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}

.link-button {
  background: none;
  border: none;
  color: var(--primary);
  font-size: 14px;
  cursor: pointer;
  padding: 0;
}

.link-button:hover {
  text-decoration: underline;
}

.session-countdown, .session-past {
  font-size: 14px;
  color: var(--text-muted);
  padding: 8px 0;
  text-align: center;
}

.no-bookings, .no-slots {
  text-align: center;
  padding: 40px 0;
  color: var(--text-muted);
}

.status-badge.cancelled {
  background-color: var(--danger);
  color: white;
}

/* Virtual Session */
.virtual-session {
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--header-height));
  background-color: #1a1a1a;
  color: white;
  position: relative;
}

.session-header {
  padding: 16px 24px;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  z-index: 10;
}

.session-header h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

.session-controls {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.control-btn {
  padding: 8px 16px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border: none;
  border-radius: var(--radius-md);
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
}

.control-btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

.control-btn.active {
  background-color: var(--primary);
}

.control-btn.recording.active {
  background-color: var(--danger);
}

.control-btn.end-session {
  background-color: var(--danger);
}

/* Main content area for the session */
.main-session-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

/* Video container that takes full screen */
.video-container.fullscreen {
  flex: 1;
  display: flex;
  position: relative;
  background-color: #000;
}

.video-box {
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Remote video takes most of the screen */
.video-box.remote {
  flex: 1;
  width: 100%;
  height: 100%;
}

/* Local video is picture-in-picture */
.video-box.local {
  position: fixed; /* Changed from absolute to fixed */
  width: 25%;
  max-width: 240px;
  min-width: 160px; /* Added minimum width */
  height: auto;
  aspect-ratio: 4/3;
  bottom: 80px; /* Increased to account for bottom controls */
  right: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border: 2px solid rgba(255, 255, 255, 0.2);
  z-index: 50; /* Increased z-index to ensure it stays on top */
  transition: all 0.3s ease; /* Smooth transition for any size changes */
  cursor: move; /* Indicates the element is draggable */
}

.video-box.local .drag-handle {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 12px;
  padding: 2px 4px;
  border-radius: 0 0 0 4px;
  cursor: move;
  opacity: 0.5;
  transition: opacity 0.2s ease;
}

.video-box.local:hover .drag-handle {
  opacity: 1;
}

.remote-video, .local-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-label {
  position: absolute;
  bottom: 8px;
  left: 8px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
}

/* Bottom Controls */
.session-bottom-controls {
  position: fixed; /* Changed from absolute to fixed */
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 12px;
  display: flex;
  justify-content: center;
  z-index: 40; /* Increased z-index but below local video */
  transition: transform 0.3s ease;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3); /* Added shadow for better visibility */
}

.bottom-control-buttons {
  display: flex;
  gap: 12px;
}

.bottom-control-btn {
  padding: 8px 16px;
  background-color: rgba(255, 255, 255, 0.15);
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.bottom-control-btn:hover {
  background-color: rgba(255, 255, 255, 0.25);
  transform: translateY(-2px);
}

.bottom-control-btn.active {
  background-color: var(--primary);
}

/* Popup Panels */
.session-panel {
  position: fixed; /* Changed from absolute to fixed */
  bottom: 60px;
  right: 20px;
  width: 350px;
  max-height: 60%;
  background-color: rgba(30, 30, 30, 0.95);
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 30; /* Increased z-index but still below local video */
  animation: panel-slide-in 0.3s ease;
}

@keyframes panel-slide-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.panel-header h3 {
  margin: 0;
  font-size: 16px;
  color: white;
}

.panel-close-btn {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  cursor: pointer;
  padding: 4px 8px;
  transition: color 0.2s ease;
}

.panel-close-btn:hover {
  color: white;
}

.notes-list, .files-list {
  padding: 16px;
  overflow-y: auto;
  max-height: 250px;
}

.note-item, .file-item {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: var(--radius-md);
  padding: 12px;
  margin-bottom: 12px;
}

.note-text {
  margin-bottom: 8px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.9);
}

.note-timestamp, .file-timestamp {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  text-align: right;
}

.add-note, .upload-file {
  padding: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.add-note textarea {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  margin-bottom: 8px;
  width: 100%;
  border-radius: 4px;
  padding: 8px;
}

.add-note button, .upload-file button {
  background-color: var(--primary);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: var(--radius-md);
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease;
}

.add-note button:hover, .upload-file button:hover {
  background-color: var(--primary-light);
}

.file-item a {
  color: var(--primary-light);
  text-decoration: none;
}

.file-item a:hover {
  text-decoration: underline;
  color: var(--primary);
}

.upload-file input {
  margin-bottom: 8px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 8px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
}

.no-notes, .no-files {
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  padding: 16px 0;
  font-style: italic;
}

/* Audio Settings Panel */
.audio-settings-panel {
  width: 400px !important;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  max-height: 80vh; /* Ensure it doesn't exceed viewport height */
}

.audio-settings-content {
  padding: 16px;
  overflow-y: auto;
  max-height: calc(60vh - 50px); /* Account for header height */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

/* Customize scrollbar for webkit browsers */
.audio-settings-content::-webkit-scrollbar {
  width: 8px;
}

.audio-settings-content::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.audio-settings-content::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

.audio-settings-content::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}

.settings-section {
  margin-bottom: 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.settings-section h4 {
  margin: 0 0 12px 0;
  font-size: 16px;
  color: white;
}

.settings-description {
  margin-bottom: 16px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
}

.device-select {
  width: 100%;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  border-radius: 4px;
  margin-bottom: 12px;
}

.audio-test-btn {
  width: 100%;
  padding: 10px;
  background-color: var(--primary);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.audio-test-btn:hover {
  background-color: var(--primary-light);
}

.audio-test-btn:disabled {
  background-color: rgba(255, 255, 255, 0.2);
  cursor: not-allowed;
}

/* Microphone level visualizer */
.mic-level-container {
  margin-top: 15px;
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.mic-level-label {
  font-size: 14px;
  color: white;
  margin-bottom: 8px;
}

.mic-level-bar-container {
  height: 20px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.mic-level-bar {
  height: 100%;
  width: 0%;
  background: linear-gradient(90deg, #2ecc71, #27ae60);
  border-radius: 10px;
  transition: width 0.1s ease-out;
}

.mic-level-text {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-style: italic;
}

.troubleshooting-section h4 {
  margin: 0 0 12px 0;
  font-size: 16px;
  color: white;
}

.troubleshooting-tips {
  padding-left: 20px;
  margin: 0 0 20px 0;
}

.troubleshooting-tips li {
  margin-bottom: 8px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
}

.browser-support-note {
  padding: 12px;
  background-color: rgba(255, 255, 0, 0.1);
  border-radius: 4px;
  margin-top: 16px;
}

.browser-support-note p {
  margin: 0 0 8px 0;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.9);
}

.browser-support-note p:last-child {
  margin-bottom: 0;
}

.browser-fallback-tip {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px !important;
  color: rgba(255, 255, 255, 0.8) !important;
  border-left: 3px solid var(--primary);
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 18px;
  color: var(--gray-600);
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid var(--primary);
  width: 40px;
  height: 40px;
  margin-bottom: 16px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-message {
  background-color: rgba(245, 101, 101, 0.15);
  border: 1px solid var(--danger);
  color: white;
  padding: 16px;
  border-radius: var(--radius-md);
  margin: 16px auto;
  max-width: 90%;
  text-align: center;
  animation: error-pulse 2s infinite;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

@keyframes error-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(229, 62, 62, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(229, 62, 62, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(229, 62, 62, 0);
  }
}

.alert {
  padding: 16px;
  border-radius: var(--radius-md);
  margin-bottom: 24px;
  font-size: 14px;
}

.alert-danger {
  background-color: rgba(245, 101, 101, 0.1);
  border: 1px solid var(--danger);
  color: var(--danger);
}

.alert-warning {
  background-color: rgba(246, 173, 85, 0.1);
  border: 1px solid var(--warning);
  color: var(--text-dark);
}

.alert-success {
  background-color: rgba(72, 187, 120, 0.1);
  border: 1px solid var(--success);
  color: var(--success);
}

.small {
  font-size: 12px;
}

/* Enhanced Status Badges */
.status-badge {
  font-size: 12px;
  padding: 4px 10px;
  border-radius: 12px;
  font-weight: 600;
  background-color: var(--gray-200);
  letter-spacing: 0.5px;
  text-transform: capitalize;
  display: inline-flex;
  align-items: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  margin-left: 5px;
}

/* Live session indicators */
.booking-status-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.status-badge.live {
  background-color: rgba(239, 68, 68, 0.15);
  color: rgb(239, 68, 68);
  border: 1px solid rgba(239, 68, 68, 0.3);
  font-weight: 700;
  display: flex;
  align-items: center;
}

.live-indicator {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: rgb(239, 68, 68);
  border-radius: 50%;
  margin-right: 5px;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(239, 68, 68, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 5px rgba(239, 68, 68, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(239, 68, 68, 0);
  }
}

.booking-card.live {
  border-left-color: rgb(239, 68, 68);
  box-shadow: 0 0 0 1px rgba(239, 68, 68, 0.1), 0 3px 10px rgba(239, 68, 68, 0.2);
}

.btn.btn-primary.join-live {
  background: linear-gradient(145deg, #ef4444, #b91c1c);
  border-color: #b91c1c;
}

.status-badge.available {
  background-color: rgba(56, 161, 105, 0.15);
  color: var(--success);
  border: 1px solid rgba(56, 161, 105, 0.3);
}

.status-badge.booked, 
.status-badge.confirmed {
  background-color: rgba(9, 114, 211, 0.15);
  color: var(--primary);
  border: 1px solid rgba(9, 114, 211, 0.3);
}

.status-badge.cancelled {
  background-color: rgba(229, 62, 62, 0.15);
  color: var(--danger);
  border: 1px solid rgba(229, 62, 62, 0.3);
}

.status-badge.completed {
  background-color: rgba(102, 102, 102, 0.15);
  color: var(--gray-700);
  border: 1px solid rgba(102, 102, 102, 0.3);
}

/* Footer Styles */
.footer {
  background-color: var(--bg-light);
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding: 32px 0;
  margin-top: 32px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 16px;
}

.footer-logo {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.footer-logo span {
  font-size: 20px;
  font-weight: 600;
  color: var(--primary-dark);
  margin-left: 8px;
}

.footer-links {
  display: flex;
  gap: 24px;
  margin-bottom: 8px;
}

.footer-links a {
  color: var(--text-dark);
  text-decoration: none;
  font-size: 14px;
}

.footer-links a:hover {
  color: var(--primary);
  text-decoration: underline;
}

.footer-copyright {
  color: var(--text-muted);
  font-size: 12px;
}

/* Responsive */
@media (max-width: 1200px) {
  .virtual-session {
    flex-direction: column;
  }
  
  .video-container {
    flex-direction: column;
    padding: 16px;
    flex: none;
    height: 60vh;
  }
  
  .video-box.local {
    width: 25%; /* Adjusted for medium screens */
    min-width: 150px;
    bottom: 70px;
  }
  
  .session-sidebar {
    width: 100%;
    flex-direction: row;
    border-left: none;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .notes-section, .files-section {
    flex: 1;
    height: 40vh;
  }
  
  .notes-section {
    border-bottom: none;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
  }
}

/* Additional media query for mobile devices */
@media (max-width: 768px) {
  .video-box.local {
    width: 30%; /* Larger percentage on smaller screens */
    min-width: 120px;
    max-width: 180px;
    bottom: 65px;
    right: 10px;
  }
  
  .session-panel {
    width: 90%;
    max-width: 350px;
    right: 5%;
    left: 5%;
    margin: 0 auto;
  }
  
  .audio-settings-panel {
    width: 95% !important;
    max-width: 95vw;
    max-height: 85vh; /* Larger height on mobile */
    bottom: 50px; /* Position closer to bottom control bar */
  }
  
  .audio-settings-content {
    max-height: calc(85vh - 50px);
  }
}

@media (max-width: 768px) {
  .header-nav {
    display: none;
    position: absolute;
    top: 64px;
    left: 0;
    width: 100%;
    background-color: white;
    flex-direction: column;
    box-shadow: var(--shadow-md);
    padding: 16px;
    gap: 8px;
    z-index: 100;
  }
  
  .header-nav.show {
    display: flex;
  }
  
  .header-menu-toggle {
    display: block;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    margin-right: 16px;
  }
  
  .form-row {
    flex-direction: column;
  }
  
  .slots-container, .teacher-list, .bookings-list {
    grid-template-columns: 1fr;
  }
  
  .session-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .session-header h2 {
    margin-bottom: 16px;
  }
  
  .session-sidebar {
    flex-direction: column;
  }
  
  .notes-section, .files-section {
    width: 100%;
    height: auto;
    max-height: 30vh;
  }
  
  .notes-section {
    border-right: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
}

/* Role Switcher Styles */
.role-switcher-container {
  position: relative;
}

.role-switcher-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #f0f8ff;
  color: #2a6496;
  font-weight: 500;
  border-radius: 4px;
}

.role-switcher-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 200px;
}

.role-option {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 10px 16px;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  transition: background-color 0.2s;
}

.role-option:hover {
  background-color: #f5f5f5;
}

.role-option.active {
  background-color: #e6f3ff;
  font-weight: 500;
}

.role-icon-small {
  font-size: 14px;
  opacity: 0.7;
}

.active-role-selector {
  margin-top: 20px;
  background-color: #f0f8ff;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid rgba(9, 114, 211, 0.2);
}